import axios from 'axios';
import { Toast } from 'vant';
import router from '@/routers/index';
Toast.allowMultiple();
const http = axios.create({
  baseURL: process.env.NODE_ENV === 'production' ? '' : '/api',
});

const headleError = (error) => {
  Toast(
    error?.response?.data?.msg || error?.msg || error?.message || '未知错误'
  );
  console.debug(error);
};

http.interceptors.request.use(
  (config) => {
    // token tenant
    const token = localStorage.getItem('app_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    headleError(error);
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  function (response) {
    const resData = response.data;
    if (resData?.code == 200) {
      return resData;
    } else if (resData?.code == 401) {
      headleError(resData);
      localStorage.clear();
      return router.replace('/login').catch(() => {});
    } else {
      headleError(resData);
      return Promise.reject(resData);
    }
  },
  function (error) {
    headleError(error);
    return Promise.reject(error);
  }
);

export default http;
